import blueimp from 'blueimp-gallery/js/blueimp-gallery.min';
import { getContent} from "./helper";

export function Gallery() {
  const button = document.getElementById('galleries__more');
  if (!button) { return; }

  button.addEventListener('click', (event) => {
    event.preventDefault();

    const loadMore = event.target;
    const url = loadMore.getAttribute('href');
    getContent(url, updateGalleriesData, loadMore);
  });

  function updateGalleriesData(json, target) {
    if (json.page) {
      target.setAttribute('href', json.page);
    } else {
      target.remove();
    }

    const galleries = document.getElementById('albums_id');
    let websitePrefix = document.querySelector('[data-website-root-path]')?.getAttribute('data-website-root-path');
    if (!websitePrefix) {
      websitePrefix = ''
    }

    json.galleries.forEach(gallery => {
      const href = document.createElement('a');
      href.setAttribute('href', `${websitePrefix}/galleries/${gallery.id}`);
      href.classList.add('gallery');
      href.style.backgroundImage = `url('${gallery.image}')`
      href.innerHTML = `
      <div class="gallery__info">
        <p class="gallery__title">${gallery.title}</p>
        <p class="gallery__date">${gallery.date}</p>
      </div>
      `;
      galleries.appendChild(href);
    })
  }
}

export function GalleryShowPage() {
  if (document.getElementById('mygallery1')) {
    document.getElementById('mygallery1').onclick = function (event) {
      event = event || window.event;
      var target = event.target || event.srcElement;
      var link = target.src ? target.parentNode : target;
      var options = { index: link, event: event };
      var links = this.getElementsByTagName('a');
      blueimp(links, options);
    }
  }
}