// const allBtnClass = 'virtual-reception__btns';
// const btnClass = 'virtual-reception__btn';
// const tabName = "virtual-reception__tab";
// const nextText = 'Следующий шаг';
// const submitText = 'Отправить';
const activeFormClass = 'virtual-reception__tab-active';
const formErrorClass = 'virtual-reception__error';
const formId = 'virtual-reception__form';
const FORBIDDEN_TERMINAL_CHARACTERS = [
  `!`,
  `#`,
  `$`,
  `%`,
  `&`,
  `'`,
  `*`,
  `+`,
  `/`,
  `=`,
  `?`,
  `^`,
  '`',
  `{`,
  `|`,
  `}`,
  `~`
]

export function VirtualReception() {
  const exampleForm = document.getElementById(formId);
  if (!exampleForm) { return; }
  removeErrorClassAfterChangeListener();
  // Отправляем форму
  exampleForm.addEventListener("submit", handleFormSubmit);
  const btnNextStep = document.querySelector('[data-btntype="nextstep"]');
  const btnSubmit = document.querySelector('[data-btntype="submitformbtn"]');

  btnNextStep.addEventListener('click', (events) => {
    events.preventDefault();
    nextTab(events.target);
  });
  btnSubmit.addEventListener('click', (events) => {
    events.preventDefault();
    submitTab(events.target);
  });
  const selectWhois = document.querySelector('[name="question[whois]"]');
  selectWhois.addEventListener('input', (event) => {
    if (event.target.value === 'Ввести в ручную') {
      // selectWhois.removeEventListener('input');
      const input = document.createElement('input');
      input.placeholder = 'Кто Вы?';
      input.name = 'question[whois]';
      event.target.parentNode.replaceChild(input, event.target);
    }
  })

  const selectTo = document.querySelector('[name="question[to]"]');
  selectTo.addEventListener('input', (event) => {
    if (event.target.value === 'Ввести в ручную') {
      // selectWhois.removeEventListener('input');
      const input = document.createElement('input');
      input.placeholder = 'Кому адресован вопрос?';
      input.name = 'question[to]';
      input.style.width = '100%';
      event.target.parentNode.replaceChild(input, event.target);
    }
  })

  function nextTab(btn) {
    const currentTab = document.querySelector('[data-tabname="nextstep"]');
    if (!validateForm(currentTab)) { return; }
    currentTab.classList.remove(activeFormClass);
    btn.classList.add('d-none');
    const nextTab = document.querySelector('[data-tabname="submitformbtn"]');
    const btnSubmit = document.querySelector('[data-btntype="submitformbtn"]');
    nextTab.classList.add(activeFormClass);
    btnSubmit.classList.remove('d-none');
  }

  function submitTab(btn) {
    const currentTab = document.querySelector('[data-tabname="submitformbtn"]');
    if (!validateForm(currentTab)) { return; }
    currentTab.classList.remove(activeFormClass);
    btn.classList.add('d-none');
    const nextTab = document.querySelector('[data-tabname="loading"]');
    nextTab.classList.add(activeFormClass);
    document.getElementById(formId).requestSubmit();
  }
}

// После нажатия кнопки "Далее" для не валидных input`s добавляется класс formErrorClass
// Данная функция вешает листенеры, 
// которые будут удалять данный класс после любого изменения значения input`s
function removeErrorClassAfterChangeListener() {
  for (let el of document.querySelectorAll(`.${formId} input`)) {
    el.addEventListener('input', (event) => {
      event.target.classList.remove(formErrorClass);
    });
  }
  for (let el of document.querySelectorAll(`.${formId} select`)) {
    el.addEventListener('input', (event) => {
      event.target.classList.remove(formErrorClass);
    });
  }
  for (let el of document.querySelectorAll(`.${formId} textarea`)) {
    el.addEventListener('input', (event) => {
      event.target.classList.remove(formErrorClass);
    });
  }
  for (let el of document.querySelectorAll(`.virtual-reception__checkbox-require`)) {
    const input = el.querySelector('input');
    input.addEventListener('change', (event) => {
      el.classList.remove(formErrorClass);
    });
  }
}


function emailIsValid(email) {
  let syntaxGood = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  if (!syntaxGood) return false // skip loop if we've already failed

  for (let badChar of FORBIDDEN_TERMINAL_CHARACTERS) {
    if (email.startsWith(badChar) || email.endsWith(badChar)) {
      return false // terminate early
    }
  }

  return true
}

// Валидируем форму
function validateForm(tab) {
  const inputs = tab.querySelectorAll("input");
  const selects = tab.querySelectorAll("select");
  const areas = tab.querySelectorAll("textarea");
  const checkboxes = tab.querySelectorAll(".virtual-reception__checkbox-require");
  let valid = true;
  for (let input of inputs) {
    if (!input.value || /^ *$/.test(input.value)) {
      input.classList.add(formErrorClass);
      valid = false;
    } else {
      if (input.dataset.type === 'email' && !emailIsValid(input.value)) {
        input.classList.add(formErrorClass);
        valid = false;
      }
    }
  }
  for (let select of selects) {
    if (!select.value) {
      select.classList.add(formErrorClass)
      valid = false;
    }
  }
  for (let input of areas) {
    if (!input.value || /^ *$/.test(input.value)) {
      input.classList.add(formErrorClass);
      valid = false;
    }
  }
  for (let check of checkboxes) {
    const input = check.querySelector('input');
    if (!input.checked) {
      check.classList.add(formErrorClass);
      valid = false;
    }
  }
  return valid;
}

async function handleFormSubmit(event) {
  event.preventDefault();

  const form = event.currentTarget;
  const url = form.action;

  try {
    const formData = new FormData(form);
    await postFormDataAsJson({ url, formData });

    const currentTab = document.querySelector('[data-tabname="loading"]');
    currentTab.classList.remove(activeFormClass);
    const nextTab = document.querySelector('[data-tabname="success"]');
    nextTab.classList.add(activeFormClass);
  } catch (error) {
    const currentTab = document.querySelector('[data-tabname="loading"]');
    currentTab.classList.remove(activeFormClass);
    const nextTab = document.querySelector('[data-tabname="error"]');
    nextTab.classList.add(activeFormClass);
  }
}

async function postFormDataAsJson({ url, formData }) {
  // const plainFormData = Object.fromEntries(formData.entries());
  // const formDataJsonString = JSON.stringify(plainFormData);
  const token = document.getElementsByName('csrf-token')[0].content;

  const fetchOptions = {
    method: "POST",
    headers: {
      'X-CSRF-Token': token
    },
    body: formData,
  };

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  return response.json();
}
