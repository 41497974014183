import { cardSwipper } from "./card";
import { Gallery, GalleryShowPage } from "./gallery";
import { initMainNews } from "./main_news";
import { initNews } from "./news";
import { initHeader, HeaderTransparentOnScroll, headerInit } from "./header"
import { tvsuHeader } from "./header_resize";
import { mobileMenu } from "./mobile_menu";
import { EventCalendar } from "./events";
import { lowVision } from "./low_vision";
import { VirtualReception } from "./virtual_reception";
import { youtubeOmbed } from "./youtube";
import { externalLink } from "./external_link";
import { initYandexMetrica } from "./yandex_metrica";
import { enableSharedButtons } from "./share";
// import searchPlaceholder from "./js/web/search";

export function initWebsiteJs() {
  try {
    cardSwipper('top-cards');
  } catch (error) {
    console.log('top-cards error');
  }
  try {
    cardSwipper('bottom-cards');
  } catch (error) {
    console.log('bottom-cards error');
  }
  try {
    Gallery();
  } catch (error) {
    console.log('Gallery error');
  }
  try {
    GalleryShowPage();
  } catch (error) {
    console.log('GalleryShowPage error');
  }
  try {
    initMainNews();
  } catch (error) {
    console.log('initMainNews error');
  }
  try {
    EventCalendar();
  } catch (error) {
    console.log('EventCalendar error');
  }
  try {
    lowVision();
  } catch (error) {
    console.log('lowVision error');
  }
  try {
    initHeader();
  } catch (error) {
    console.log('initHeader error', error);
  }
  try {
    HeaderTransparentOnScroll();
  } catch (error) {
    console.log('HeaderTransparentOnScroll error');
  }
  try {
    headerInit();
  } catch (error) {
    console.log('headerInit error');
  }
  try {
    tvsuHeader();
  } catch (error) {
    console.log('tvsuHeader error', error);
  }
  try {
    mobileMenu();
  } catch (error) {
    console.log('mobileMenu error');
  }
  try {
    initNews();
  } catch (error) {
    console.log('initNews error');
  }
  try {
    VirtualReception();
  } catch (error) {
    console.log('VirtualReception error');
  }
  try {
    youtubeOmbed();
  } catch (error) {
    console.log('youtubeOmbed error');
  }
  try {
    externalLink();
  } catch (error) {
    console.log('externalLink error');
  }
  try {
    initYandexMetrica();
  } catch (error) {
    console.log('yandex metrica error');
  }
  try {
    enableSharedButtons();
  } catch (error) {
    console.log('enableSharedButtons error', error);
  }
}

