import { initNews } from "./news";
import { tvsuHeader } from "./header_resize";

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setFontSize(fontSize) {
  document.querySelector('html').style.fontSize = fontSize;
}

function setColor(value) {
  const [colorName, bgName] = value.split(',');
  const fontSize = document.querySelector('html').style.fontSize;
  document.documentElement.setAttribute("style", `
                                                  --main-color: ${bgName};
                                                  --second-color: ${colorName};
                                                  --thrid-color: ${colorName};
                                                  --white-text: ${colorName};`
                                                  
  );
  document.querySelector('html').style.fontSize = fontSize;
}

function enableDisableImage(enable) {
  if (enable) {
    document.querySelectorAll('img').forEach(e => {
      e.classList.add('lowVisionDisplayNone');
    })
  } else {
    document.querySelectorAll('img').forEach(e => {
      e.classList.remove('lowVisionDisplayNone');
    })
  }
  const btn = document.querySelector('#img-onoff-btn');
  if (!btn) { return; }
  // const enableText = document.querySelector('html').getAttribute('lang') === 'en' ? 'Включить' : 'Enable';
  // const disableText = document.querySelector('html').getAttribute('lang') === 'en' ? 'Выключить' : 'Disable';
  const btn_enable = document.querySelector('#img-onoff-text-enable');
  const btn_disable = document.querySelector('#img-onoff-text-disable');
  // btn.innerHTML = enable ? enableText : disableText;
  if(enable) {
    btn_enable.classList.remove('d-none')
    btn_disable.classList.add('d-none')
  } else {
    btn_enable.classList.add('d-none')
    btn_disable.classList.remove('d-none')
  }
}

function setLineHeight(value) {
  if (value === '1') {
    document.querySelector('body').classList.remove('lowVisionEnableMiddleLineHeight');
    document.querySelector('body').classList.remove('lowVisionEnableBigLineHeight');
  }
  if (value === '2') {
    document.querySelector('body').classList.add('lowVisionEnableMiddleLineHeight');
    document.querySelector('body').classList.remove('lowVisionEnableBigLineHeight');
  }
  if (value === '3') {
    document.querySelector('body').classList.remove('lowVisionEnableMiddleLineHeight');
    document.querySelector('body').classList.add('lowVisionEnableBigLineHeight');
  }
}

function setfontFamily(value) {
  if (value === '1') {
    document.querySelector('body').classList.remove('Arial');
    document.querySelector('body').classList.remove('Times');
  }
  if (value === '2') {
    document.querySelector('body').classList.add('Arial');
    document.querySelector('body').classList.remove('Times');
  }
  if (value === '3') {
    document.querySelector('body').classList.remove('Arial');
    document.querySelector('body').classList.add('Times');
  }
}


function init() {
  const enableLowVision = getCookie('lowVision');
  if (enableLowVision && enableLowVision === 'on') {
    document.querySelector('body').classList.add('lowVisionColorEnable');
    const panel = document.getElementById('sv_wrapper');
    panel.style.display = 'flex';

    const lowVisionFontSize = getCookie('lowVisionFontSize') ? getCookie('lowVisionFontSize') : '16px';
    const lowVisionColorName = getCookie('lowVisionColorName') ? getCookie('lowVisionColorName') : 'black,white';
    const lowVisionDisableImage = getCookie('lowVisionDisableImage') ? getCookie('lowVisionDisableImage') : 'on';
    const lowVisionLineHeight = getCookie('lowVisionLineHeight') ? getCookie('lowVisionLineHeight') : '1';
    const lowVisionfontFamily = getCookie('lowVisionfontFamily') ? getCookie('lowVisionfontFamily') : '1';

    if (lowVisionFontSize) { setFontSize(lowVisionFontSize); }
    if (lowVisionColorName) { setColor(lowVisionColorName); }
    if (lowVisionDisableImage) { enableDisableImage(getCookie('lowVisionDisableImage') === 'on'); }
    if (lowVisionLineHeight) { setLineHeight(lowVisionLineHeight); }
    if (lowVisionfontFamily) { setfontFamily(lowVisionfontFamily); }

    initNews();
    tvsuHeader();
  }
}

function disable() {
  document.cookie = 'lowVision=off; path=/';
  document.querySelector('html').style.fontSize = "16px";
  const panel = document.getElementById('sv_wrapper');
  panel.style.display = 'none';
  document.querySelector('body').classList.remove('lowVisionColorEnable');
  document.documentElement.removeAttribute("style");
}

export function lowVision() {
  init();

  const enableBtn = document.getElementById('lowVisible');
  enableBtn.addEventListener('click', (event) => {
    event.preventDefault();
    document.cookie = 'lowVision=on; path=/';
    init();
  });

  //font Size
  document.querySelectorAll('.fs-outer button').forEach(el => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const fontSize = event.target.getAttribute('data-fontSize');
      document.cookie = `lowVisionFontSize=${fontSize}; path=/`;
      init();
    });
  });

  document.querySelectorAll('.cs-outer button').forEach(el => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const bgName = event.target.getAttribute('data-bgName');
      const colorName = event.target.getAttribute('data-colorName');
      document.cookie = `lowVisionColorName=${bgName},${colorName}; path=/`;
      init();
    });
  });

  document.querySelectorAll('#img-onoff-btn').forEach(el => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const enable = !!getCookie('lowVisionDisableImage') && getCookie('lowVisionDisableImage') !== 'on';
      document.cookie = `lowVisionDisableImage=${enable ? 'on' : 'off'}; path=/`;
      init();
    });
  });

  document.querySelectorAll('.line_height-outer button').forEach(el => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const value = event.target.getAttribute('data-fontSize');
      document.cookie = `lowVisionLineHeight=${value}; path=/`;
      init();
    });
  });


  document.querySelectorAll('.font_family-outer button').forEach(el => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const value = event.target.getAttribute('data-fontFamily');
      document.cookie = `lowVisionfontFamily=${value}; path=/`;
      init();
    });
  });

  const closeBtn = document.getElementById('sv_close_btn');
  closeBtn.addEventListener('click', (event) => {
    event.preventDefault();
    disable();
  })
}
