export function getContent(url, callBack, target) {
  fetch(url, {
    headers: {
      "Accept": "application/json",
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(result => { callBack(result, target); })
}

//TestThis
// function createElement(name, classList, attributes, value) {
//   const el = document.createElement(name);
//   classList.split(' ').map(cl => {
//     el.classList.add(cl);
//   });
//   for (let key in attributes) {
//     el.setAttribute(key, attributes[key]);
//   }
//   if (value) { el.innerHTML = value; }
//   return el;
// }