function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Функция для установки кросс-доменного cookie
function setCrossDomainCookie(name, value, domain) {
  // Устанавливаем значения для cookie
  var cookieValue = name + "=" + encodeURIComponent(value) + ";";

  // Добавляем атрибут domain для кросс-доменности
  // cookieValue += "domain=" + domain + ";";

  // Устанавливаем путь cookie
  cookieValue += "path=/;";

  // Добавляем атрибуты secure и SameSite=None для отправки cookie при кросс-доменных запросах
  cookieValue += `secure;SameSite=None;max-age=${60 * 60 * 24 * 365}`;

  // Устанавливаем cookie
  document.cookie = cookieValue;
}

export function initYandexMetrica() {
  const messageElement = document.querySelector('#cookie-notification');
  // Если нет cookies, то показываем плашку
  const agree = getCookie('cookies-agreement')
  if (agree) {
    initCounter();
  } else {
    showMessage();
  }

  // Функция, которая показывает предупреждение
  function showMessage() {
    messageElement.classList.add('cookie-notification__show');
  }

  function initCounter() {
    // Загружаем сам код счетчика сразу
    // (function (m, e, t, r, i, k, a) {
    //   m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
    //   m[i].l = 1 * new Date();
    //   k = e.createElement(t);
    //   a = e.getElementsByTagName(t)[0];
    //   k.async = 1;
    //   k.src = r;
    //   a.parentNode.insertBefore(k, a);
    // }) (window, document, 'script', '//mc.yandex.ru/metrika/tag.js', 'ym');
    // ym(87554514, 'init', { clickmap: false, trackLinks: true, accurateTrackBounce: true, webvisor: false });
    (function (d, w, c) {
      (w[c] = w[c] || []).push(function () {
        try {
          w.yaCounter87554514 = new Ya.Metrika({
            id: 87554514,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true
          });
        } catch (e) { }
      });

      var n = d.getElementsByTagName("script")[0],
        x = "https://mc.yandex.ru/metrika/watch.js",
        s = d.createElement("script"),
        f = function () { n.parentNode.insertBefore(s, n); };
      for (var i = 0; i < document.scripts.length; i++) {
        if (document.scripts[i].src === x) { return; }
      }
      s.type = "text/javascript";
      s.async = true;
      s.src = x;

      if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
      } else { f(); }
    })(document, window, "yandex_metrika_callbacks");

    messageElement.style.display = 'none';
    setCrossDomainCookie('cookies-agreement', '1', '.localhost');
  }
  // Нажатие кнопки "Я согласен"
  document.querySelector('#cookie-notification-yes').addEventListener('click', function () {
    initCounter();
  });
}

// (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
// m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
// (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
// ym(87554514, "init", {clickmap:false,trackLinks:true,accurateTrackBounce:true,webvisor:false});