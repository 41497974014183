import {
  Swiper,
  Navigation,
  Scrollbar
} from 'swiper';

Swiper.use([Navigation, Scrollbar]);

export function initMainNews() {
  const mainNews = new Swiper(".main-news-swiper", {
    centeredSlides: true,
    scrollbar: {
      el: ".main-news__swiper-scrollbar",
      draggable: true,
      snapOnRelease: true,
    }
  });

  mainNews.on('slideChange', function () {
    const items = document.querySelectorAll('.main-news-item');
    const index = mainNews.activeIndex;
    const new_item = document.querySelector(`.main-news-item[data-index='${index}']`);
    items.forEach(el => { el.classList.remove('active'); })
    new_item.classList.add('active');
  });

  document.querySelectorAll('.main-news-item').forEach((element, index) => {
    element.addEventListener('mouseenter', (event) => {
      mainNews.slideTo(index, 250, true);
    })
  });
}