const calendarYearSelect = "[data-event-calendar-attr='year_select']";
const calendarMonthSelect = "[data-event-calendar-attr='year_month']";
const calendarDateSelect = "[data-event-calendar-attr='day']";
const calendarDateClassName = 'calendar__date';
const calendarDateHaveEventsClassname = 'calendar__date-have_events';
const calendarDateDisabledClassName = 'calendar__date-disabled';
const calendarDateSelectedClassName = 'calendar__date-selected';
const calendarCurrentDaysOfMonthWithEvents = `.${calendarDateClassName}.${calendarDateHaveEventsClassname}:not(.${calendarDateDisabledClassName})`;

function createHtmlElement(type, innerHTML, classNames, styles) {
  let el = document.createElement(type);
  if (innerHTML) {
    if (typeof innerHTML === 'string') {
      el.innerHTML = innerHTML;
    } else {
      el.append(innerHTML);
    }
  }
  if (classNames) { el.classList = classNames; }
  if (styles) {
    el.style.cssText = styles
  }
  return el;
}

function createDayHtmlElement(day, classList) {
  const dayHTML = document.createElement("td");
  dayHTML.innerHTML = day;
  dayHTML.classList = `${classList.reduce((accumulator, currentValue) => accumulator + ' ' + currentValue)}`;
  return dayHTML;
}

function getMonthsForLocale(locale) {
  let format = new Intl.DateTimeFormat(locale, { month: 'long' })
  let months = []
  for (let month = 0; month < 12; month++) {
    let testDate = new Date(Date.UTC(2001, month, 1, 0, 0, 0));
    months.push(format.format(testDate))
  }
  return months;
}

export function EventCalendar(el, title = null, week_props = null) {
  const yearSelect = document.querySelector(calendarYearSelect);
  const monthSelect = document.querySelector(calendarMonthSelect);
  if (yearSelect && monthSelect) {
    listener(monthSelect, yearSelect);
  }
}

function listener(monthSelect, yearSelect) {
  const date = new Date();
  let month = date.getMonth();
  let year = date.getFullYear();
  const day = date.getDate();

  let $selectedDay = {
    day: null,
    month: month,
    year: year
  }

  const updateCalendar = (year, month) => {
    let websitePrefix = document.querySelector('[data-website-root-path]')?.getAttribute('data-website-root-path');
    if (!websitePrefix) {
      websitePrefix = '/'
    }

    fetch(`${websitePrefix}calendars?year=${parseInt(year)}&month=${parseInt(month)}`)
      .then(response => response.json())
      .then(data => {
        drawCalendar(data?.entries, month)
      })
  }

  const initCalendar = (yearSelect, monthSelect, year, month, day) => {
    let websitePrefix = document.querySelector('[data-website-root-path]')?.getAttribute('data-website-root-path');
    if (!websitePrefix) {
      websitePrefix = '/'
    }

    fetch(`${websitePrefix}calendars?year=${parseInt(year)}&month=${parseInt(month)}&with_years=true`)
      .then(response => response.json())
      .then(data => {
        setYearSelected(yearSelect, year, data?.years);
        setMonthSelected(monthSelect, month);
        drawCalendar(data?.entries, month);
        setFirstDay(day);
      })
  }

  function setFirstDay(day) {
    let days = document.querySelectorAll(calendarCurrentDaysOfMonthWithEvents);
    for (const html of days) {
      const current_day = parseInt(html.innerHTML);
      if (current_day >= day) {
        html.click();
        $selectedDay = {
          ...$selectedDay,
          day: current_day,
        }
        return;
      }
    }
  }

  function setYearSelected(yearSelect, year, years) {
    years.forEach(val => {
      let opt = document.createElement('option');
      opt.value = val;
      opt.innerHTML = val;
      opt.selected = val === year;
      yearSelect.appendChild(opt);
    })
  }
  function setMonthSelected(monthSelect, month) {
    const currentLocale = document.querySelector('html').getAttribute('lang') === 'en' ? 'en-EN' : 'ru-RU';
    getMonthsForLocale(currentLocale).forEach((val, index) => {
      let opt = document.createElement('option');
      opt.value = index;
      opt.innerHTML = val;
      opt.selected = index === month;
      monthSelect.appendChild(opt);
    })
  }

  function drawCalendar(data, month) {
    let calendar = document.querySelector(calendarDateSelect);
    calendar.innerHTML = "";
    let week = [];
    data.forEach(day => {
      let classes = [calendarDateClassName];
      if (parseInt(day.month) !== month) { classes.push(calendarDateDisabledClassName); }
      if (day.news.length !== 0) { classes.push(calendarDateHaveEventsClassname); }
      if ($selectedDay.day === day.day && $selectedDay.month === day.month && $selectedDay.year === day.year) {
        classes.push(calendarDateSelectedClassName);
      }
      const day_ = createDayHtmlElement(day.day, classes);
      if (day.news.length !== 0) {
        day_.addEventListener('click', () => {
          for (const day of document.getElementsByClassName(calendarDateClassName)) {
            day.classList.remove(calendarDateSelectedClassName);
          }
          $selectedDay = {
            day: day.day,
            month: day.month,
            year: day.year
          }
          day_.classList.add(calendarDateSelectedClassName);
          drawEvents(day.news)
        })
      }
      week.push(day_);
      if (week.length === 7) {
        const row = document.createElement('tr');
        week.map(t => row.append(t))
        calendar.append(row);
        week = [];
      }
    })
  }

  function drawEvents(data) {
    let wrapper = document.querySelector('.events');
    wrapper.innerHTML = '';
    let events = createHtmlElement("div", null, 'events__wrapper');
    data.forEach((news, index) => {
      events.append(drawDay(news, index === 0));
    })

    wrapper.append(events);

    if (data.length > 1) {
      let prev = createHtmlElement("div", createHtmlElement("span"), "event-news__prev");
      let next = createHtmlElement("div", createHtmlElement("span"), "event-news__next");
      prev.addEventListener('click', () => { NextPrevCliked(-1) });
      next.addEventListener('click', () => { NextPrevCliked(1) });
      wrapper.append(prev);
      wrapper.append(next);
    }
  }

  function drawDay(data, first) {
    const currentLocale = document.querySelector('html').getAttribute('lang') === 'en' ? 'en-EN' : 'ru-RU';
    const date = new Date(data.date).toLocaleDateString(currentLocale, { year: 'numeric', month: 'long', day: 'numeric' });
    const text = document.querySelector('html').getAttribute('lang') === 'en' ? 'Details' : 'Подробности';
    const prev = `
          <img class="event-news__image" src="${data.preview}" alt="" />
          <div class="event-news-text">
            <div class="event-news-text__wrapper">
              <span class="event-news-text__title">${data.title}</span>
              <div class="event-news-text__more-block">
                <a class="event-news-text__more with-arrow-right" href="${data.url}">${text}<i></i></a>
                <span class="event-news-text__date">${data.tag_title} / ${date}</span>
              </div>
            </div>
          </div>
    `;
    const to_return = document.createElement('div');
    to_return.classList.add("events-news__news");
    if (!first) { to_return.style.display = 'none'; }
    to_return.innerHTML = prev;

    return to_return;
  }

  monthSelect.addEventListener('change', (event) => {
    month = parseInt(event.target.value);
    updateCalendar(year, month);
  });
  yearSelect.addEventListener('change', (event) => {
    year = parseInt(event.target.value);
    updateCalendar(year, month);
  });
  initCalendar(yearSelect, monthSelect, year, month, day);
}

function NextPrevCliked(step) {
  let events = document.querySelectorAll('.events-news__news');
  let active_event_index = 0;
  for (const e of events) {
    if (e.style.display !== 'none') {
      break;
    }
    active_event_index++;
  }
  // let active_event_index = [...events].findIndex(e => e.style.display === 'flex');
  events[active_event_index].style.display = 'none';

  let new_index = active_event_index + step;
  if (new_index < 0) { new_index = events.length - 1 }
  if (new_index >= events.length) { new_index = 0 }
  events[new_index].style.display = 'flex';
}