export function enableSharedButtons() {
  const items = [
    { name: 'vkontakte', url: 'https://vk.com/share.php', title: 'title' },
    { name: 'odnoklassniki', url: 'https://connect.ok.ru/offer', title: 'title' },
    { name: 'telegram', url: 'https://t.me/share/url', title: 'text' },
  ]
  for (let item of items) {
    const el = document.querySelector(`.share__item_service_${item.name}`)
    if (el) {
      el.querySelector('a').href = `${item.url}?url=${window.location.href}&${item.title}=${document.title}&utm_source=share2`
    }
  }
}