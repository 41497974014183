export function initHeader() {
  // if (!document.querySelector('.header__init')) {
  //   return;
  // }

  const header = document.getElementById("header");
  for (const el of header.querySelectorAll('.header__menu-link.arrow-down')) {
    el.addEventListener('click', linkClicked)
  }
  for (const el of header.querySelectorAll('.header__menu-link.header__title')) {
    el.addEventListener('click', linkClicked)
  }
  for (const el of document.querySelectorAll('.header__lang')) {
    el.addEventListener('click', langClicked)
  }
  headerLeave();

  const submenu = document.querySelector('.subsitemenu');
  if (submenu) {
    for (const el of submenu.querySelectorAll('.header__menu-link.arrow-down')) {
      el.addEventListener('click', linkClicked2)
    }
  }
  headerLeave2();
}

function submenu(event) {
  const target = event.target;
  const parent = target.parentElement;
  const list = parent?.querySelector('.dropdown-menu__items');
  if (!list) { return; }
  target.classList.toggle('with-arrow-down');
  target.classList.toggle('with-arrow-right');
  list.classList.toggle('d-none');
}

function linkClicked(event) {
  event.preventDefault();
  for (const el of document.getElementsByClassName('header__menu-link')) {
    el.classList.remove('active');
  }
  let target = event.target;
  if (!target.classList.contains('arrow-down')) {
    target = target.parentElement;
  }
  target?.classList.add('active');
  const href = target;
  document.getElementById('header').classList.add('header__menu_opened');

  if (document.querySelector('.header-faculties')) { return; }

  const content = href.querySelector('.dropdown-menu');

  document.querySelector('.submenu__content').innerHTML = '';
  const newContent = content.cloneNode(true);
  document.querySelector('.submenu__content').append(newContent);
  for (const el of newContent.querySelectorAll('.dropdown-menu__text')) {
    el.addEventListener('click', submenu);
  }
}

function linkClicked2(event) {
  event.preventDefault();
  for (const el of document.getElementsByClassName('header__menu-link')) {
    el.classList.remove('active');
  }
  let target = event.target;
  if (!target.classList.contains('arrow-down')) {
    target = target.parentElement;
  }
  target?.classList.add('active');
  const href = target;
  document.querySelector('.subsitemenu')?.classList.add('subsitesubmenu_opened');

  const content = href.querySelector('.dropdown-menu');

  document.querySelector('.subsitesubmenu__content').innerHTML = '';
  const newContent = content.cloneNode(true);
  document.querySelector('.subsitesubmenu__content').append(newContent);
  for (const el of newContent.querySelectorAll('.dropdown-menu__text')) {
    el.addEventListener('click', submenu);
  }
}

function headerLeave() {
  document.addEventListener("click", (evt) => {
    const header = document.getElementById("header");
    const lang = document.getElementById('header__lang');
    if (!header) { return; }

    let targetEl = evt.target; // clicked element      
    do {
      if (lang?.classList.contains('active')) {
        if (targetEl === lang) { return; }
      }
      if (targetEl === header) {
        if (lang?.classList.contains('active')) {
          lang?.classList.remove('active');
        }
        return;
      }
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    // document.querySelector('.subsitemenu')?.classList.remove('subsitesubmenu_opened');
    header?.classList.remove('header__menu_opened');
    for (const el of document.getElementsByClassName('header__menu-link')) {
      el.classList.remove('active');
    }
    document.getElementById('header__lang')?.classList.remove('active');
  });
}

function headerLeave2() {
  document.addEventListener("click", (evt) => {
    const flyoutEl = document.querySelector('.subsitemenu');
    if (!flyoutEl) { return; }

    let targetEl = evt.target; // clicked element
    do {
      if (targetEl === flyoutEl) {
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    document.querySelector('.subsitemenu')?.classList.remove('subsitesubmenu_opened');
    for (const el of document.getElementsByClassName('header__menu-link')) {
      el.classList.remove('active');
    }
  });
}

export function HeaderTransparentOnScroll() {
  const header = document.querySelector('.header');
  if (!header) {
    return;
  }
  if (window.pageYOffset > 20) {
    header.classList.add('header-background')
  } else {
    header.classList.remove('header-background')
  }
  window.addEventListener('scroll', function () {
    if (window.pageYOffset > 20 && document.body.clientWidth > 1109) {
      header.classList.add('header-background')
    } else {
      if (!header.classList.contains('header--active')) {
        header.classList.remove('header-background');
      }
    }
  });
}

export function headerInit() {
  const header = document.getElementById('header');
  if (header) {
    header.classList.remove('header__init');
  }
}

function langClicked(event) {
  // event.preventDefault();
  for (const el of document.getElementsByClassName('header__menu-link')) {
    el.classList.remove('active');
  }
  let target = this;
  target?.classList.toggle('active');
  const langMenu = document.querySelector('.header__lang-content');
  langMenu?.classList.toggle('active');
}
