import Masonry from 'masonry-layout';
import { getContent } from './helper';

const newsContentClassName = 'news__content';
export function initNews() {
  if (document.getElementsByClassName(newsContentClassName).length === 0) {
    return;
  }
  MassonryNews();
  newsInitialization(getIntInnerHtml("news-total-page"));
  addListener();
}

function MassonryNews() {
  for (let el of document.getElementsByClassName(newsContentClassName)) {
    new Masonry(el, {
      itemSelector: '.news-item',
      fitWidth: true,
    });
  }
}

function updateNewsCount(newsSize, totalNews, url) {
  document.getElementById("news-size").innerHTML = newsSize;
  document.getElementById("news-all-count").innerHTML = totalNews;
  document.getElementById("news-all-count").setAttribute('href', url);
}

function newsInitialization(lastPage, newsSize, totalNews, urlAttr) {
  const { urlParams, otherParams } = getUrlParams(urlAttr);
  const params = new URLSearchParams(urlParams);
  const url = `${window.location.pathname}?${params.toString()}`;
  if (lastPage > 0) { newsPaginationUpdate(otherParams.page, lastPage, url); }
  newsTagSetActive(otherParams.tag, otherParams.exceptTag);
  if (newsSize !== undefined && totalNews !== undefined) {
    updateNewsCount(newsSize, totalNews, url);
  }
}

function addListener() {
  for (const id of ['news-prev-page', 'news-next-page', 'news-last-page']) {
    const el = document.getElementById(id);
    if (el) {
      el.addEventListener('click', (event) => { 
        newsLinkListener(event);
        document.querySelector(
          ".breadcrumbs"
        ).scrollIntoView();
      });
    }
  }
  for (const el of document.getElementsByClassName('news-tags__item')) {
    if (el) {
      el.addEventListener('click', (event) => { newsLinkListener(event); });
    }
  }
}

function newsLinkListener(event) {
  event.preventDefault();
  const url = event.target.getAttribute('href');
  if (window.location.pathname !== '/') {
    window.history.pushState("string", "Title", event.target.getAttribute('href'));
  }
  getContent(url, updateNews, event.target);
}

function updateNews(result, target) {
  const parent = document.querySelector('.news__content');

  let allHtml = '';
  result.news.forEach(news => {
    allHtml += createNewsHtml(news);
  });
  parent.innerHTML = allHtml;

  newsInitialization(result.lastPage, result.newsSize, result.totalNews, target.getAttribute('href'));
  MassonryNews();
}

function newsPaginationUpdate(page, totalPageValue, url) {
  const prevPage = document.getElementById("news-prev-page");
  const nextPage = document.getElementById("news-next-page");
  const lastPage = document.getElementById("news-last-page");
  const currentPage = document.getElementById("news-current-page");
  const totalPage = document.getElementById("news-total-page");
  if (!totalPage) { return; }

  const className = 'pagination__link-disable';
  prevPage.classList[page > 1 ? 'remove' : 'add'](className);
  nextPage.classList[page < totalPageValue ? 'remove' : 'add'](className);
  lastPage.classList[page < totalPageValue ? 'remove' : 'add'](className);

  prevPage.setAttribute('href', `${url}&page=${page - 1}`);
  nextPage.setAttribute('href', `${url}&page=${page + 1}`);
  lastPage.setAttribute('href', `${url}&page=${totalPageValue}`);

  currentPage.innerHTML = page;
  totalPage.innerHTML = totalPageValue;
}

function newsTagSetActive(tagName, exceptTag) {
  for (const tag of document.getElementsByClassName('news-tags__item')) {
    tag.classList.remove('active');
  }
  if (exceptTag) {
    const expectTag = document.querySelector('.news-tags__item[data-tagType="expect"]');
    expectTag.classList.add('active');
    return;
  }
  if (!tagName) {
    const allTag = document.querySelector('.news-tags__item[data-tagType="all"]');
    allTag.classList.add('active');
    return;
  }
  const tag = document.querySelector(`.news-tags__item[data-tag="${tagName}"]`);
  if (tag) { tag.classList.add('active'); }
}

function getUrlParams(urlProps = window.location.search) {
  const base = window.location.origin;
  const url = new URL( urlProps, base );

  const queryString = url.searchParams;
  const page = parseInt(queryString.get('page'));
  const tag = queryString.get('tag');
  const exceptTag = queryString.get('tag_except');
  const urlParams = {};
  if (tag) { urlParams.tag = tag; }
  if (exceptTag) { urlParams.exceptTag = exceptTag; }
  return {
    urlParams: urlParams,
    otherParams: {
      tag: tag,
      exceptTag: exceptTag,
      page: page ? page : 1
    }
  }
}
function getIntInnerHtml(id) {
  const lastPage = document.getElementById(id);
  if (lastPage) { return parseInt(lastPage.innerHTML); }
  return -1;
}
function createNewsHtml(news) {
  let websitePrefix = document.querySelector('[data-website-root-path]')?.getAttribute('data-website-root-path');
  if (!websitePrefix) {
    websitePrefix = ''
  }

  const str = news.image ?
    `<a class="news-item news-item-image" href="${websitePrefix}${news.url}" style="background-image: url('${news.image}');">`
    :
    `<a class="news-item" href="${websitePrefix}${news.url}">`
  return `
      ${str}
        <div class="news-item__content">
          <h5 class="news-item__title">${news.title}</h5>
          <div class="news-item__info">
            <span class="news-item__tag">${news.tag}</span>
            <span class="news-item__delimetr"> / </span>
            <span class="news-item__date">${news.date}</span>
          </div>
        </div>
      </a>`;
}
