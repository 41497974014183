export function tvsuHeader() {
  if (document.querySelector('.header-faculties')) { return; }
  document.getElementById('header__menu')?.classList.remove('d-none');
  document.getElementById('header__phone')?.classList.remove('d-none');
  const header = document.querySelector('.header');

  const minTitleSize = 20;
  const padding = 15;
  const logoSize = 49;
  const logoWithTextSize = 192;
  const titleMargin = 50;
  const titleSize = header.querySelector('#header__title') ? header.querySelector('#header__title').clientWidth : 0;
  // const menuSize = document.getElementById('header__menu').clientWidth;
  let menuSize = 0;
  const langSize = document.querySelector('#header__lang') ? document.querySelector('#header__lang')?.clientWidth : 0;
  const phoneSize = document.querySelector('#header__phone') ? document.querySelector('#header__phone').clientWidth : 0;
  const burgerSize = 32;

  for (const it of header.getElementsByClassName('header__menu-link')) {
    menuSize = menuSize + it.clientWidth + 20;
  }
  menuSize += 30;

  const widthLogoWithTextProps = getWidthProps(logoWithTextSize, 'logo-with-text', 'logo-short');
  const widthLogoProps = getWidthProps(logoSize, 'logo-short', 'logo-with-text');
  let widthProps = [];
  for (let i = 0; i < 4; i++) {
    widthProps.push(widthLogoWithTextProps[i]);
    widthProps.push(widthLogoProps[i]);
  }
  window.addEventListener('resize', (event) => {
    mobileHeaderTitle();
  });
  function getWidthProps(logoSize, logoName, disabledLogonNme) {
    return [
      {
        disabled: ['header__burger', disabledLogonNme],
        enabled: [logoName, 'header__title', 'header__menu', 'header__lang', 'header__phone'],
        mobileTitle: false,
        burger: false,
        size: padding + logoSize + titleMargin + titleSize + menuSize + langSize + phoneSize
      },
      {
        disabled: [disabledLogonNme, 'header__menu', 'header__phone'],
        enabled: [logoName, 'header__title', 'header__lang', 'header__burger'],
        mobileTitle: false,
        burger: true,
        size: padding + logoSize + titleMargin + titleSize + langSize + burgerSize
      },
      {
        disabled: [disabledLogonNme, 'header__title', 'header__menu', 'header__phone'],
        enabled: [logoName, 'header__lang', 'header__burger'],
        mobileTitle: true,
        burger: true,
        size: padding + logoSize + minTitleSize + langSize + burgerSize
      },
    ]
  }
  function mobileHeaderTitle() {
    const pageWidth = document.body.clientWidth - 25;
    let notFountWidth = true;
    for (let i = 0; notFountWidth && i < widthProps.length; i++) {
      notFountWidth = test(widthProps[i], pageWidth);
    }
  }
  function test(props, pageWidth) {
    const submenu = document.querySelector('.subsitemenu');
    if (props.size > pageWidth) { return true; }
    if (submenu && pageWidth + 25 <= 1110 && !props.burger) { return true; }

    props.disabled.forEach(el => {
      document.getElementById(el)?.classList.add('d-none');
    });
    props.enabled.forEach(el => {
      document.getElementById(el)?.classList.remove('d-none');
    });
    if (props.mobileTitle) {
      document.getElementById('header__title-mobile').classList.remove('d-none');
    } else {
      document.getElementById('header__title-mobile').classList.add('d-none');
    }

    return false;
  }
  mobileHeaderTitle();
}