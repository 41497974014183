export function mobileMenu() {
  const burger = document.getElementById('header__burger');
  if (!burger) {return;}
  burger.addEventListener('click', (event) => {
    document.getElementById("mobile__menu").classList.add('mobile__open2');
    document.body.classList.add('lock');
  });


  document.getElementById('mobile__close__btn').addEventListener('click', (event) => {
    document.getElementById("mobile__menu").classList.remove('mobile__open2');
    document.body.classList.remove('lock');
  });

  for(const el of document.querySelectorAll('.mobile__menu__link-submenu')) {
    el.addEventListener('click', swichMenuLevel);
  }
}

function swichMenuLevel(event) {
  event.preventDefault();
  const target = event.target;
  const url = target.getAttribute('href');
  const menu = document.querySelector(url);
  if(!menu) { return; }
  for (const el of document.querySelectorAll('.mobile__menu__level')) {
    el.classList.add('d-none');
  }
  menu.classList.remove('d-none');
}