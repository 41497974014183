export function externalLink() {
  document.querySelectorAll('a').forEach(link => {
    if(!link.href) {return;}

    const startWithSlash = new RegExp('^' + window.location.origin)
    if (startWithSlash.test(link.href)) { return; }

    const containtTversuRu = new RegExp('.*tversu.ru$')
    const linkWithRemovedHttpsAndPath = link.href.replace(/^http[s]*:\/\//, '').replace(/\/.*/, '')
    if (containtTversuRu.test(linkWithRemovedHttpsAndPath)) { return; }

    const langList = link.getAttribute('data-lang') === 'true';
    if (langList) { return; }

    const startWithHttp = new RegExp('^http[s]*://')
    if (!startWithHttp.test(link.href)) { return; }

    link.addEventListener("click", function (event) {
      event.preventDefault();
      if (window.confirm("Вы переходите на внешний сайт. Продолжить?")) {
        const anchor = document.createElement('a');
        anchor.href = this.href;
        anchor.target = "_blank";
        anchor.rel = "noopener noreferrer";
        anchor.click();
      }
    }, false)
  })
}
