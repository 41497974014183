import {
  Swiper,
  Navigation,
  Scrollbar
} from 'swiper';

Swiper.use([Navigation, Scrollbar]);

export function cardSwipper(prefix) {
  new Swiper(`.${prefix}__swiper`, {
    slidesPerView: 4.3,
    spaceBetween: 48,
    scrollbar: {
      el: `.${prefix}__scrollbar`,
      draggable: true,
      snapOnRelease: true,
    },
    navigation: {
      nextEl: `.${prefix}__next`,
      prevEl: `.${prefix}__prev`,
    },

    mousewheel: {
      forceToAxis: true
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      340: {
        slidesPerView: 1.1,
        spaceBetween: 20,
      },
      400: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      550: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      650: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1000: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
      1350: {
        slidesPerView: 3.5,
        spaceBetween: 48,
      },
      1650: {
        slidesPerView: 4.3,
        spaceBetween: 48,
      },
    }
  });

}
