require.context('../images', true);
// require.context('../js/web/', true);
// require('../js/web/application');
import { initWebsiteJs } from '../js/web/application';

// let waitReady = true;
// document.onreadystatechange = function () {
//   if (waitReady && (document.readyState === "interactive" || document.readyState === "complete")) {
//     waitReady = false;
//     initWebsiteJs();
//   }
// };

document.addEventListener("DOMContentLoaded", function(event) {
  initWebsiteJs();
})