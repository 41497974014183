export function youtubeOmbed() {
  function insetYoutube(figure, oembed) {
    const raw_url = oembed.getAttribute('url');
    if (!raw_url && raw_url.indexOf('youtube.com') === -1 && raw_url.indexOf('youtu.be') === -1) { return; }
    const url = raw_url.replace(/watch\?v=/, 'embed/').replace(/youtu\.be/, 'youtube.com/embed/');
    let iframe = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('title', 'YouTube video player');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', true);

    iframe.setAttribute('src', url);
    figure.parentNode.insertBefore(iframe, figure);
    figure.style.display = 'none';
  }

  document.querySelectorAll('figure[class="media"]').forEach(el => {
    el.childNodes.forEach(link => {
      insetYoutube(el, link);
    })
  })
}